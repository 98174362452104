export default class Image365 {
    public Day = 0;
    public File = '';
    public Text = '';
    public Title = '';

    constructor(day: number, file: string, text?: string, title?: string) {
        this.Day = day;
        this.File = file;
        if (text) {
            this.Text = text;
        }
        if (title) {
            this.Title = title;
        }
    }
}
