




















import { Component, Vue, Watch } from 'vue-property-decorator';
import Image365 from '@/Image365';
@Component
export default class Drawing extends Vue {
    private images: Image365[] = [];
    private day = 0;
    private hasValidId = false;
    private get routeId(): string {
        return this.$route.params.id;
    }

    private get image(): Image365 {
        return (
            this.images.find((i) => i.Day === this.day) ?? new Image365(0, '')
        );
    }

    @Watch('routeId', { immediate: true })
    private onRouteIdChanged(newVal: string, oldVal: string) {
        if (newVal === oldVal) {
            return;
        }

        this.setCurrentImage(newVal);
    }

    private getImgUrl(image: Image365): string {
        const images = require.context('../assets/365/', false, /\.png$/);
        return images('./' + image.File);
    }

    private getImageDescription(image: Image365): string {
        let res = image.Day.toString();
        if (image.Title) {
            res += ' - ' + image.Title;
        }
        return res;
    }

    private setCurrentImage(id: any) {
        if (!isNaN(+id)) {
            this.hasValidId = false;
        }
        this.day = this.images.find((i) => i.Day === +id) ? +id : 0;
        this.hasValidId = this.day > 0;
    }

    private created() {
        this.loadImages();
        this.setCurrentImage(this.routeId);
    }

    private loadImages() {
        this.images.push(
            new Image365(
                2,
                '1.png',
                'More info on image 2',
                'This one has a title'
            )
        );
        this.images.push(new Image365(1, '1.png', 'Text below the image'));
    }
}
